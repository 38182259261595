.supportLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #8d8888;
  border-radius: 13px;
  height: 100vh;
  background: #ffffff;
}

.supportLayout > div {
  height: 100%;
}

.supportLayout .chatList {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.supportLayout .chatList .chat-list-chats ::-webkit-scrollbar {
  width: 8px;
}
.supportLayout .chatList .chat-list-chats ::-webkit-scrollbar-thumb {
  background: #3a94ee;
  border-radius: 15px;
}

.supportLayout .chat {
  width: 45%;
  border-left: 1px solid #8d8888;
  border-right: 1px solid #8d8888;
  display: flex;
  flex-direction: column;
}

.supportLayout .chat ::-webkit-scrollbar {
  width: 8px;
}
.supportLayout .chat ::-webkit-scrollbar-thumb {
  background: #3a94ee;
  border-radius: 15px;
}
.supportLayout .chat .chat-left {
  margin-right: auto;
}
.supportLayout .chat .chat-right {
  margin-left: auto;
}
.supportLayout .chat .chat-box-container {
  max-width: 60%;
  margin-bottom: 10px;
}
.supportLayout .chat .chat-box-time {
  color: #292929;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  text-align: right;
  margin: 5px 0;
}
.supportLayout .chat .chat-box {
  padding: 12px 6px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  background: #f8f8f8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #054089;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}

.supportLayout .chat .chat-attachment {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
}

.supportLayout .chat .chat-attachment .attachment-file {
  width: 100px;
  height: 100px;
  border: 1px solid #d2d2d2;
  background: lightgray;
  border-radius: 10px;
  word-wrap: break-word;
  padding: 5px;
}

.supportLayout .chat .chat-attachment img {
  border-radius: 10px;
  background: #d2d2d2;
}

.supportLayout .chat .chat-box p,
.supportLayout .chat .chat-box h1,
.supportLayout .chat .chat-box h2,
.supportLayout .chat .chat-box h3,
.supportLayout .chat .chat-box h4,
.supportLayout .chat .chat-box h5,
.supportLayout .chat .chat-box h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.supportLayout .chat .chat-box ul,
.supportLayout .chat .chat-box ol {
  margin-left: 15px;
}
.supportLayout .chatInfo {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.supportLayout .file-preview {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: white;
  border: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supportLayout .loading-overlay,
.supportLayout .error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.supportLayout .loading-overlay .loading-overlay-loader {
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.supportLayout .loading-overlay {
  color: white;
}

.supportLayout .error-overlay {
  color: red;
}

.supportLayout .attachment-delete-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
}
