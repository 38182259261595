.page-action-disabled {
  opacity: 0.3;
}
.next-page-arrow {
  color: #000;
}
.prev-page-arrow {
  color: #000;
}
.pagination-holder {
  display: flex;
  justify-content: end;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  font-family: "Inter", sans-serif;
  align-items: center;
}
.pagination-wrapper {
  display: flex;
  list-style-type: none;
}
.pagination-page-link {
  display: none;
}
.prev-page-arrow,
.next-page-arrow {
  color: #999999;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.pagination-text {
  font-size: 13px;
  line-height: 15px;
  color: #19114a;
  font-family: "Inter", sans-serif;
}
.pagination-holder {
  display: flex;
  align-items: center;
}
.pagination-holder span {
  font-size: 13px;
  line-height: 15px;
  color: #19114a;
  padding-left: 10px;
  font-family: "Inter", sans-serif;
}
.pagination-holder ul {
  font-size: 16px;
  color: #19114a;
  line-height: 22px;
  padding-left: 8px;
}