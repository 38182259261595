.copy-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.copy-tooltip-element {
  opacity: 0;
  visibility: hidden;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: calc(100% + 10px);
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 20px 2px lightgray;
  padding: 5px 15px;
  gap: 5px;
  font-size: 16px;
  cursor: pointer;
}

.copy-tooltip:hover .copy-tooltip-element {
  opacity: 1;
  visibility: visible;
}

.copy-tooltip-element:hover {
  background: lightgray;
}
