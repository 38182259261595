@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.cus-inter {
  font-family: "Inter", sans-serif;
}
.pagination-holder {
  display: flex;
  justify-content: end;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  font-family: "Inter", sans-serif;
  align-items: center;
}
.pagination-wrapper {
  display: flex;
  list-style-type: none;
}
.pagination-page-link {
  display: none;
}
.prev-page-arrow,
.next-page-arrow {
  color: #999999;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.pagination-text {
  font-size: 13px;
  line-height: 15px;
  color: #19114a;
  font-family: "Inter", sans-serif;
}
.pagination-holder {
  display: flex;
  align-items: center;
}
.pagination-holder span {
  font-size: 13px;
  line-height: 15px;
  color: #19114a;
  padding-left: 10px;
  font-family: "Inter", sans-serif;
}
.pagination-holder ul {
  font-size: 16px;
  color: #19114a;
  line-height: 22px;
  padding-left: 8px;
}
.pagination-text select {
  font-size: 13px;
  line-height: 15px;
  color: #19114a;
  font-family: "Inter", sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
}
.filter_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table_manipulation_btn {
  padding: 5px;
  margin: 5px;
  border: 1px solid #3a94ee;
  color: #3a94ee;
  cursor: pointer;
  background-color: transparent;
}
.table_cell_text {
  color: black;
  font-weight: 400;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.quill.ql-error .ql-toolbar,
.quill.ql-error .ql-container {
  border-color: #f43f5e;
}

.custom_scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(255, 255, 255);
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: #3a94ee; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners of the scrollbar thumb */
}

.custom_scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #2e8eee; /* Color of the scrollbar thumb on hover */
}
.list-color:hover {
  background-color: #f9f9f9;
}
.cus-text::first-letter {
  text-transform: capitalize;
}
.cus-posts-text_color {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  font-family: "Inter";
  background: none;
  border: none;
  color: #0a0529f2;
}
.cus-posts-text_color:hover {
  color: #3a94ee;
  transition: 0.2s;
  cursor: pointer;
}
.cus-posts-text_color:disabled {
  color: #8d8c8ccc;
  transition: 0.2s;
  cursor: auto;
}
.cus_date_dropdown {
  font-size: 14px;
  letter-spacing: 16px;
  font-weight: 500;
  font-family: "Inter";
  margin-bottom: 10px;
}
.cus_category_dropdown {
  font-size: 14px;
  letter-spacing: 16px;
  font-weight: 500;
  font-family: "Inter";
}
.cus-date-dropdown-btn {
  margin-left: 10px;
  background: #ffff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter";
  color: #7d888d;
  font-weight: 500;
}
.cus-date-dropdown-btn:hover {
  color: #3a94ee;
  transition: 0.2s;
}

.cus-role {
  display: flex !important;
  justify-content: center !important;
}
.post-tooltip {
  z-index: 999;
}
.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cus_posts_category_table_truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
}
input[type="file"] {
  /* height: 36px !important;
  padding: 7px 8px !important;
  border-radius: 4px !important; */
  height: 36px;
  padding: 7px 8px;
  border-radius: 4px;
}
input[type="file"]::file-selector-button {
  margin-right: 10px;
  border: none;
  background: #227be9;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #1d6fd4;
}

#menu-
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ujkx8i-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  > ul {
  background-color: #fff;
  max-height: 270px;
  overflow: auto;
  padding: 0;
}
.ql-toolbar.ql-snow {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.ql-container.ql-snow {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.drop-container {
  position: relative;
  border-radius: 10px;
  border: 2px solid #dbd9d9;
  width: 337px;
  height: 48px;
}
.all-posts-drop-container {
  position: relative;
  border-radius: 10px;
  height: 48px;
  width: 100%;
}
.all-posts-button-container {
  border-radius: 10px;
  border: 2px solid #dbd9d9;
  width: 100%;
  cursor: pointer;
  padding: 0px 10px;
}
.choose-file-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.all-posts-input-container {
  display: flex !important;
  align-items: center;
  gap: 10px;
  position: absolute;
  margin: auto 10px;
  inset: 0;
}
.btn-upload-csv-file:disabled {
  opacity: 80%;
  cursor: auto;
}
.btn-upload-csv-file {
  position: absolute;
  inset: 0;
  margin: auto;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 8px 15px;
  width: 110px;
  border: none;
  border-radius: 24px;
  background-color: #227be9;
}
.cus-input-style {
  /* width: 100%; */
  height: 100%;
  opacity: 0;
  padding: 42px 0px;
  cursor: pointer;
  position: relative;
  z-index: 20;
}
.input-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  margin: auto auto;
  inset: 0;
}
.drop-button-container {
  border-radius: 10px;
  border: 2px solid #dbd9d9;
  min-width: 337px;
}
.button-container {
  width: 100%;
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown_category {
  color: black;
  cursor: pointer;
  padding: 6px 10px;
}
.dropdown_category:hover {
  background-color: #3a94ee;
  color: white;
  border-radius: 6px;
}
.react-select-container {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}
.react-select__value-container {
  padding: 8.5px 12px !important;
}
.react-select__dropdown-indicator path {
  display: none;
}
.selector-city-btn {
  z-index: 1000px;
  position: relative;
}
.cus-dropdown-style {
  color: #555555;
}

.react-select-container,
.react-select-red-container {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}
.react-select__value-container,
.react-select-red__value-container {
  padding: 8.5px 12px !important;
}
.react-select__dropdown-indicator,
.react-select-red__dropdown-indicator path {
  display: none;
}
.react-select-red__control {
  border-color: red !important;
}
.cus-post-edit-button {
  background-color: red;
}
.skill_truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 580px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.post_truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1340px) {
  .post_truncate {
    width: 200px;
  }
  .skill_truncate {
    width: 350px;
  }
}
@media screen and (max-width: 900px) {
  .post_truncate {
    width: 150px;
  }
  .skill_truncate {
    width: 250px;
  }
}
.css-1clqzm-MuiButtonBase-root-MuiMenuItem-root {
  color: white !important;
}

.ql-notification-editor .ql-container {
  height: 180px;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
