.email-tooltip-container {
  position: relative;
}
.email-tooltip {
  position: absolute;
  top: 100%;
  left: -35px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  padding: 5px 20px;
  background: white;
  box-shadow: 2px 2px 30px 4px lightgray;
  cursor: pointer;
}
.email-tooltip:hover {
  background: #f5f5f5;
}
.email-tooltip-container:hover .email-tooltip {
  opacity: 1;
  visibility: visible;
}
